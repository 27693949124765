import React from 'react';
import { ThemeProvider } from 'styled-components';

const ThemedComponentComponent = ({globalTheme, mode, opposite, includeBg, theme: { mode: themeMode, global: { colors } }, children}) => {
  const oppositeTheme = (themeMode === 'light') ? 'dark' : 'light';
  const useMode = (mode) ? mode : (opposite) ? oppositeTheme : themeMode;
  const style = (includeBg) ? { backgroundColor: colors['background-back'][useMode] } : {};
  return (
    <ThemeProvider theme={{...globalTheme, mode: useMode}}>
      <div style={style}>
        {children}
      </div>
    </ThemeProvider>
  );
};

export default ThemedComponentComponent;
