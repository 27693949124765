import React, { forwardRef, useState } from 'react'
import { StyledSwitchContainer, StyledSwitchInputContainer, StyledSwitchLabel, StyledSwitchSlider, StyledSwitchInput } from 'shared-ui/src/components/ui/Switch/styles';
import { StyledSwitchSliderIcons } from './styles';

const SwitchComponent = forwardRef(
  (
    {
      onChange,
      //checked,
      disabled,
      width,
      height,
      translate,
      backgroundColorChecked,
      backgroundColorUnchecked,
      backgroundColorButton,
      name,
      value,
      defaultValue,
      labelRight,
      labelLeft,
      sliderWidth,
      sliderHeight,
      sliderIconLeft,
      sliderIconRight,
      className
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const handleInternalOnChange = e => setInternalValue(e.value);
    const handleOnChange =
      value !== undefined ? onChange : handleInternalOnChange;
    const val = value !== undefined ? value : internalValue;

    return (
      <StyledSwitchContainer className={className}>
        {labelLeft && <StyledSwitchLabel>{labelLeft}</StyledSwitchLabel>}
        <StyledSwitchInputContainer width={width} height={height}>
          <StyledSwitchInput
            ref={ref}
            onChange={handleOnChange}
            type="checkbox"
            name={name}
            //value={'checked'}
            checked={val}
            disabled={disabled}
            translate={translate}
            backgroundColorUnchecked={backgroundColorUnchecked}
            backgroundColorChecked={backgroundColorChecked}
          />
          <StyledSwitchSlider
            sliderWidth={sliderWidth}
            sliderHeight={sliderHeight}
            backgroundColorUnchecked={backgroundColorUnchecked}
            backgroundColorButton={backgroundColorButton}
          >
            <StyledSwitchSliderIcons>
              <div>
                {sliderIconLeft}
              </div>

              <div>
                {sliderIconRight}
              </div>
            </StyledSwitchSliderIcons>
          </StyledSwitchSlider>
        </StyledSwitchInputContainer>
        {labelRight && <StyledSwitchLabel>{labelRight}</StyledSwitchLabel>}
      </StyledSwitchContainer>
    );
  }
);

export default SwitchComponent;
