import React from 'react';
import { Provider as BumbagProvider } from 'bumbag/Provider';
import theme from 'shared-bumbag/src/theme';

const ThemeProvider = ({children}) => (
  <BumbagProvider theme={theme}>
    {children}
  </BumbagProvider>
);

export default ThemeProvider;
