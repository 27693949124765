import PropTypes from 'prop-types';
import Alert from './component';
import {
  defaultPropTypes,
  iconPropType, sizePropType, spacingPropType,
  stringOrComponentPropType,
  statusPropType, elevationPropType
} from '../propTypes';

Alert.defaultProps = {
  type: 'default',
  padding: 'sm',
  spacing: { bottom: 'sm' },
  rounded: true,
  elevation: 'base',
  onClose: () => null
};

Alert.propTypes = {
  type: statusPropType,
  message: stringOrComponentPropType,
  title: stringOrComponentPropType,
  icon: iconPropType,
  elevation: elevationPropType,
  padding: spacingPropType,
  spacing: spacingPropType,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  ...defaultPropTypes
};

export default Alert;
