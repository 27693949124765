import React, { forwardRef } from 'react';
import { StyledLoaderBg, StyledLoaderSpinner, } from './styles';

const LoaderComponent = forwardRef(
  (
    {
      bg,
      icon,
      spinner,
      fixed,
      overlay,
      ...rest
    },
    ref
  ) => {
    const attrs = {
      overlay,
      fixed
    };
    return (
      <>
        {spinner && !bg ? (
          <StyledLoaderSpinner attrs={attrs} ref={ref}>{icon}</StyledLoaderSpinner>
        ) : (
          <StyledLoaderBg ref={ref}/>
        )}
      </>
    );
  }
);

export default LoaderComponent;
