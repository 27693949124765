import React from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '../../../themes/base.plain';
import { StyledTheme } from './StyledTheme';
import { deepMerge } from '../../../utils/objects';
import {
  getBreakpoint,
  getDeviceBreakpoint,
  getResponsiveDeviceVars
} from '../../../utils/breakpoints';
import { throttle } from '../../../utils/delay';
import { ResponsiveContext } from '../../../contexts/responsive';
import { applyModeToVariables } from '../../../utils/utils';

const defaultWidth = 1600;
const defaultBreakpoint = 'lg';

class ThemeProviderComponent extends React.Component {
  constructor(props) {
    super(props);
    const { theme, dark } = this.props;
    //const defaultTheme = generate(24);
    //const theme = deepMerge(defaultTheme, themeOverride);

    /*
    const breakpoint =
      getBreakpoint(theme) ||
      this.deviceResponsive() ||
      theme.global.deviceBreakpoints.tablet;
    */

    typeof window !== 'undefined' && window.addEventListener('resize', throttle(this.onResize));

    const localMode = (typeof localStorage !== 'undefined') ? localStorage.getItem('themeMode') : undefined;
    const systemDark = (typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const startingMode = (localMode) ? localMode : (systemDark) ? 'dark' : 'light';

    this.state = {
      mode: 'light',
      theme,
      innerWidth: (typeof window !== 'undefined') ? window.innerWidth : defaultWidth,
      responsive: defaultBreakpoint,
      ...getResponsiveDeviceVars(defaultBreakpoint, theme)
    };
  }

  toggleThemeMode = () => {
    const mode = this.state.mode === 'light' ? 'dark' : 'light';
    const root = (typeof window !== 'undefined') ? window.document.documentElement : null;
    applyModeToVariables(mode, this.state.theme.global.colors);
    applyModeToVariables(mode, this.state.theme.global.elevation, 'elevation');
    applyModeToVariables(mode, this.state.theme.logo.theme, 'logo');
    if(root) root.style.setProperty('--theme-mode', mode);

    if((typeof document !== 'undefined')) {
      if(mode === 'light') {
        document.body.classList.add(`mode-light`);
        document.body.classList.remove(`mode-dark`);
      }

      if(mode === 'dark') {
        document.body.classList.add(`mode-dark`);
        document.body.classList.remove(`mode-light`);
      }
    }

    this.setState({ mode });
    localStorage.setItem('themeMode', mode);
  };

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('resize', throttle(this.onResize));
    this.onResize();
    const defaultMode = (this.props.dark !== undefined && this.props.dark) ? 'dark' : (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light';
    const localeThemeMode = localStorage.getItem('themeMode') || defaultMode;
    this.setState({
      mode: localeThemeMode,
      responsive: getBreakpoint(this.state.theme),
      innerWidth: (typeof window !== 'undefined') ? window.innerWidth : defaultWidth
    });
    typeof document !== 'undefined' && document.body.classList.add(`mode-${localeThemeMode}`);
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.onResize);
    typeof document !== 'undefined' && document.body.classList.remove(`mode-light`);
    typeof document !== 'undefined' && document.body.classList.remove(`mode-dark`);
  }

  onResize = () => {
    const { theme, responsive } = this.state;
    const breakpoint = getBreakpoint(theme);

    if (breakpoint !== responsive) {
      this.setState({
        responsive: breakpoint,
        innerWidth: window.innerWidth,
        ...getResponsiveDeviceVars(breakpoint, theme)
      });
    }
  };

  deviceResponsive() {
    // TODO: Not really being used...
    const { theme } = this.state;
    const userAgent =
      typeof navigator !== 'undefined' ? navigator.userAgent : null;
    if (userAgent) {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(userAgent))
        return getDeviceBreakpoint('tablet', theme);
      if (/Mobile|iPhone|Android/.test(userAgent))
        return getDeviceBreakpoint('phone', theme);
      return getDeviceBreakpoint('computer', theme);
    }
    return undefined;
  }

  render() {
    const { children, theme: themeOverride } = this.props;
    let { theme, mode, ...rest } = this.state;
    theme = deepMerge(theme, {
      mode,
      toggleThemeMode: this.toggleThemeMode,
      responsive: rest.responsive,
      //...baseTheme, // TEMP
      ...themeOverride
    });

    return (
      <ResponsiveContext.Provider value={{...rest, globalTheme: theme}}>
        <ThemeProvider theme={theme}>
          <StyledTheme>{children}</StyledTheme>
        </ThemeProvider>
      </ResponsiveContext.Provider>
    );
  }
}

export default ThemeProviderComponent;
