import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const ResponsiveContext = React.createContext({});

const withResponsive = Component => props => (
  <ResponsiveContext.Consumer>
    {responsive => <Component {...props} {...responsive} />}
  </ResponsiveContext.Consumer>
);

const helperDefaultProps = { wrap: false, el: 'div' };
const helperPropTypes = { wrap: PropTypes.bool, el: PropTypes.string };

const MobileView = ({ children, wrap, el: El }) => {
  const { isMobile } = useContext(ResponsiveContext);
  if (!wrap) return isMobile ? children : null;
  return isMobile ? <El>{children}</El> : null;
};

MobileView.defaultProps = helperDefaultProps;
MobileView.propTypes = helperPropTypes;

const ResponsiveView = ({ condition, children, wrap, el: El }) => {
  if (!wrap) return condition ? children : null;
  return condition ? <El>{children}</El> : null;
};

const MobileOnlyView = props => {
  const { isMobileOnly } = useContext(ResponsiveContext);
  return <ResponsiveView {...props} condition={isMobileOnly} />;
};

MobileOnlyView.defaultProps = helperDefaultProps;
MobileOnlyView.propTypes = helperPropTypes;

const TabletView = props => {
  const { isTablet } = useContext(ResponsiveContext);
  return <ResponsiveView {...props} condition={isTablet} />;
};

TabletView.defaultProps = helperDefaultProps;
TabletView.propTypes = helperPropTypes;

const ComputerView = props => {
  const { isComputer } = useContext(ResponsiveContext);
  return <ResponsiveView {...props} condition={isComputer} />;
};

ComputerView.defaultProps = helperDefaultProps;
ComputerView.propTypes = helperPropTypes;

const ComputerOrTabletView = props => {
  const { isComputerOrTablet } = useContext(ResponsiveContext);
  return <ResponsiveView {...props} condition={isComputerOrTablet} />;
};

ComputerOrTabletView.defaultProps = helperDefaultProps;
ComputerOrTabletView.propTypes = helperPropTypes;

export {
  ResponsiveContext,
  withResponsive,
  MobileView,
  MobileOnlyView,
  TabletView,
  ComputerView,
  ComputerOrTabletView
};
