import { createGlobalStyle, css } from 'styled-components';
import {
  convertObjectToVariables, cssVar,
  pluckResponsiveSizingShorthand,
  sanitizePropsWithBase, sizeToStyle
} from './utils';
import { transition } from './mixins';

//${props => convertObjectToVariables(props.theme.global.colors)}

export const BaseStyles = createGlobalStyle`

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  :root {
    ${props => convertObjectToVariables(props.theme.global.colors)}
    ${props => convertObjectToVariables(props.theme.global.elevation, 'elevation')}
    ${props => convertObjectToVariables(props.theme.logo.theme, 'logo')}
  }
  
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    margin: 0;
    font-family: ${props => props.theme.global.font.fontFamily};
    font-size: ${props => props.theme.global.fontSize.base};
    letter-spacing: ${props => props.theme.global.font.letterSpacing};
    line-height: ${props => props.theme.global.font.lineHeight};
    font-weight: ${props => props.theme.global.font.fontWeight.normal};
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${cssVar('background-back')};
    color: ${cssVar('text')};
    
    &.header-fixed {
      padding-top: ${props => sizeToStyle(props.theme.header.height)}!important;
    }
  }
  

  a {
    color: ${cssVar('link')};
    &:hover {
      color: ${cssVar('link-hover')};
    }
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
  }
  

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden],
  template {
    display: none;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:active,
  a:hover {
    outline-width: 0;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }
  .text-highlight { color: ${cssVar('text-highlight')}; }
  
`;

export const generateMediaQueries = (theme, responsiveObject, properties) => {
  let mediaQueries = ``;

  console.log(theme);

  for(const [breakpoint, value] of Object.entries(responsiveObject)) {

    mediaQueries = mediaQueries + ` 
      @media(max-width: 500px) {
        
      }
    `;

  }

  return mediaQueries;
};

export const spacing = (theme, value = 'base') => sizeToStyle(theme.global.spacing[sanitizePropsWithBase(value)]);

export const borderRadius = (theme, value = 'base') => sizeToStyle(theme.global.borderRadius[sanitizePropsWithBase(value)]);
export const borderRadiusStyle = (theme, value = 'base') => css`border-radius: ${borderRadius(theme, value)}`;

export const borderSize = (theme, value = 'base') => sizeToStyle(theme.global.borderSize[sanitizePropsWithBase(value)]);
export const borderSizeStyle = (theme, value = 'base') => css`border-width: ${borderSize(theme, value)}`;

export const borderStyle = (theme, position = 'bottom', size = 'base', type = 'solid', color = null) => {
  return css`border${position ? `-${position}` : ``}: ${borderSize(theme, size)} ${type} ${color ? color : theme.global.colors.border[theme.mode]}`;
};

export const fontSize = (theme, value = 'base', withPx = false) => sizeToStyle(theme.global.fontSize[sanitizePropsWithBase(value)]);
export const fontSizeStyle = (theme, value = 'base') => css`font-size: ${borderSize(theme, value, true)}`;

export const fontWeight = (theme, thick = true) => theme.global.font.fontWeight[thick ? 'thick' : 'normal'];
export const fontWeightStyle = (theme, thick = true) => css`font-weight: ${fontWeight(theme, thick)}`;

export const transitionStyle = (theme, properties, duration = 'base', timing = 'ease-base-out') =>
  transition({properties, duration: theme.global.animation.duration[sanitizePropsWithBase(duration)], timing: theme.global.animation.timing[timing]});

// box-shadow: ${theme.global.elevation[theme.mode][sanitizePropsWithBase(value)]};
export const elevationStyle = (theme, value = 'md') => {
  if(!value) return ``;
  return css`
    box-shadow: ${cssVar(sanitizePropsWithBase(value), 'elevation')};
  `;
};

export const marginSpacing = (theme, spacing) => {
  return (spacing) ? pluckResponsiveSizingShorthand(theme, 'spacing', sanitizePropsWithBase(spacing)) : 0;
};

export const marginSpacingStyle = (theme, spacing, inset = false) => {
  const marginStyle = marginSpacing(theme, spacing);
  if(!marginStyle) return ``;
  if(inset) return css`padding: ${marginStyle};`;
  return css`margin: ${marginStyle};`;
};


export const customCSSStyle = (theme, customCss) => {
  if(typeof customCss === 'string' && customCss) {
    return css`${customCss}`;
  } else if(typeof customCss === 'object' && customCss.light && customCss.dark) {
    return css`${customCss[theme.mode]}`;
  } else {
    return ``;
  }
};
