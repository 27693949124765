import React, { forwardRef } from 'react';
import {
  StyledThemeModeToggle
} from 'shared-ui/src/components/navigation/ThemeModeToggle/styles';
import Switch from 'shared-ui/src/components/ui/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/pro-duotone-svg-icons/faSun';
import { faMoonStars } from '@fortawesome/pro-duotone-svg-icons/faMoonStars';

const ThemeModeToggleComponent = forwardRef(
  (
    {
      theme: {
        toggleThemeMode,
        mode
      },
      ...rest},
    ref
  ) => {
    return (
      <StyledThemeModeToggle ref={ref} {...rest}>
        <Switch
          value={(mode === 'dark')}
          onChange={toggleThemeMode}
          sliderIconLeft={<FontAwesomeIcon icon={faMoonStars}/>}
          sliderIconRight={<FontAwesomeIcon icon={faSun}/>}
        />
      </StyledThemeModeToggle>
    );
  }
);

export default ThemeModeToggleComponent;
