import PropTypes from 'prop-types';
import Button from './component';
import { defaultPropTypes, elevationPropType, sizePropType, colorPropType, spacingPropType } from '../propTypes';

Button.defaultProps = {
  type: 'default',
  loading: false,
  ghost: false,
  disabled: false,
  size: 'md',
  spacing: null,
  padding: null,
  iconPosition: 'left',
  elevation: 'md',
  htmlType: 'button',
  ariaLabel: '',
  className: ''
};

Button.propTypes = {
  type: colorPropType,
  size: sizePropType,
  elevation: elevationPropType,
  loading: PropTypes.bool,
  htmlType: PropTypes.string,
  ariaLabel: PropTypes.string,
  spacing: spacingPropType,
  padding: spacingPropType,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  block: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'default']),
  ...defaultPropTypes
};

export default Button;
