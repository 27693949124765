import React, { forwardRef } from 'react';
import { StyledContainer } from 'shared-ui/src/components/ui/Container/styles';

const ContainerComponent = forwardRef(
  (
    {
      children,
      type,
      flush,
      center,
      ...rest},
    ref
  ) => {
    return (
      <StyledContainer ref={ref} {...rest} attrs={{type, flush, center}}>
        {children}
      </StyledContainer>
    );
  }
);

export default ContainerComponent;
