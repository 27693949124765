import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const StyledLoaderSpinner = styled.div`
  position: ${({attrs}) => attrs.fixed ? `fixed` : `absolute`};
  top: 0;
  left: 0;
  font-size: 40px;
  color: ${({theme: { loader }}) => loader.icon.color};
  right: 0;
  z-index: 100;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  ${({attrs, theme: { loader }}) => attrs.overlay && css`background-color: ${loader.overlay.background};`}
`;

const skeletonLoading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const StyledLoaderBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ${skeletonLoading} 1.4s ease infinite;
`;
