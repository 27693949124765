import Box from 'shared-ui/src/components/ui/Box/component';
import {
  defaultPropTypes,
  elevationPropType,
  iconPropType, roundedPropType,
  spacingPropType,
} from '../propTypes';
import PropTypes from 'prop-types';

Box.defaultProps = {
  vfull: false,
  withHeader: false,
  vcenter: false,
  center: false,
  elevation: 'none',
  spacing: null,
  padding: null,
  icon: null,
  closable: false,
  rounded: 'default',
  onClose: () => null
};

Box.propTypes = {
  vfull: PropTypes.bool,
  withHeader: PropTypes.bool,
  vcenter: PropTypes.bool,
  elevation: elevationPropType,
  spacing: spacingPropType,
  padding: spacingPropType,
  icon: iconPropType,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  rounded: roundedPropType,
  ...defaultPropTypes
};

export default Box;
