export const DEFAULT_DEVICE = 'tablet';
export const DEFAULT_BREAKPOINT = 'lg';
export const DEVICE_MOBILE = 'mobile';
export const DEVICE_TABLET = 'tablet';
export const DEVICE_COMPUTER = 'computer';

export const DEVICE_SIZES = {
  [DEVICE_MOBILE]: ['sm'],
  [DEVICE_TABLET]: ['md', 'lg'],
  [DEVICE_COMPUTER]: ['xl']
};
