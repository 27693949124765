export const removePx = string => parseInt(string, 10);
export const stringToIntNumber = string => parseInt(string);
export const stringToFloatNumber = string => parseFloat(string);

export const stringDurationToMilliseconds = (duration) => {
  const defaultDuration = 200;
  if(!duration) return defaultDuration;
  if(typeof duration === 'number') return duration;
  if(typeof duration === 'string') {
    if(duration.includes('ms')) {
      return Math.round(stringToFloatNumber(duration));
    } else if(duration.includes('s')) {
      return Math.round(stringToFloatNumber(duration) * 1000);
    } else {
      return defaultDuration;
    }
  }
  return defaultDuration;
};
