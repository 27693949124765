export const sanitizePropsWithBase = (prop) => {
  if(prop === false) return 'none';
  return (prop === 'default' || prop === 'md' || prop === true) ? 'base' : prop;
};

export const getThemedItem = (object, key, theme = 'light') => {
  return object[theme][key];
};

export const pluckColorFromTheme = (theme, variable) => {
  const color = theme.global.colors[variable];
  return (color) ? theme.global.colors[variable] : theme.global.colors.brand;
};

export const getControlProps = (theme, size) => {
  return theme.global.controls.size[size];
};

export const sizeToStyle = (size) => {
  return (typeof size == 'number') ? `${size}px` : size;
};

export const pluckBreakpointFromThemeObject = (themeObj, responsive) => {
  let useValue = themeObj[Object.keys(themeObj)[0]];

  for (const [key, value] of Object.entries(themeObj)) {
    if(key === responsive) {
      return value;
    } else {
      useValue = value;
    }
  }

  return useValue;
};

export const pluckResponsiveSizing = (theme, type = 'spacing', key = 'base') => {
  key = sanitizePropsWithBase(key);
  const { global, responsive } = theme;
  const { breakpoints } = global;
  const defaultSizing = global[type];
  const breakpointSizing = breakpoints[responsive] ? breakpoints[responsive][type] : null;
  if(!key) return (breakpointSizing) ? breakpointSizing : defaultSizing; // Return entire object if no key specified...
  const defaultSize = defaultSizing[key];
  if(!breakpointSizing) {
    return (defaultSize) ? sizeToStyle(defaultSize) : 0;
  }
  const breakpointSize = breakpointSizing[key];
  return (breakpointSize) ? sizeToStyle(breakpointSize) : defaultSize ? sizeToStyle(defaultSize) : 0;
};

export const pluckTextSizing = (theme, size, key = 'size') => {
  return theme.global.font.fontSize[size][sanitizePropsWithBase(key)];
};

export const pluckResponsiveSizingShorthand = (theme, type = 'size', keyOrObject = 'md') => {
  if(typeof keyOrObject === 'string') {
    return pluckResponsiveSizing(theme, type, keyOrObject);
  } else if(typeof keyOrObject === 'object') {
    const top = (keyOrObject['top']) ? pluckResponsiveSizing(theme, type, keyOrObject['top']) : 0;
    const right = (keyOrObject['right']) ? pluckResponsiveSizing(theme, type, keyOrObject['right']) : 0;
    const bottom = (keyOrObject['bottom']) ? pluckResponsiveSizing(theme, type, keyOrObject['bottom']) : 0;
    const left = (keyOrObject['left']) ? pluckResponsiveSizing(theme, type, keyOrObject['left']) : 0;
    return `${top} ${right} ${bottom} ${left}`;
  } else {
    return 0;
  }
};

export const cssVar = (key, prefix = `color`) => 'var(--' + prefix + '-' + key + ')';

export const convertObjectToVariables = (object, prefix = `color`, defaultMode = `light`) => {
  let style = ``;
  for (const [key, value] of Object.entries(object)) {
    if(typeof value === 'object') {
      style = (value[defaultMode]) ? style + '--' + prefix + '-' + key + ': ' + value[defaultMode] + ';' : style;
      style = style + convertObjectToVariables(value, prefix + '-' + key, defaultMode);
    } else if(value) {
      style = style + '--' + prefix + '-' + key + ': ' + value + ';';
    }
  }
  return style;
};

export const applyModeToVariables = (mode, object, prefix = `color`) => {
  const root = (typeof window !== 'undefined') ? window.document.documentElement : null;
  if(!root) return null;
  for (const [key, value] of Object.entries(object)) {
    const varName = '--' + prefix + '-' + key;
    if(typeof value === 'object' && value[mode]) {
      root.style.setProperty(varName, value[mode]);
      //console.log(varName, value[mode]);
    } else if(typeof value === 'object') {
      applyModeToVariables(mode, value, prefix + '-' + key);
    } else if(typeof value === 'string') {
      root.style.setProperty(varName, value);
    }
  }
};

export const getInitialColorMode = (defaultMode = 'light') => {
  if(typeof window !== 'undefined') {
    const persistedColorPreference = window.localStorage.getItem('themeMode');
    const hasPersistedPreference = typeof persistedColorPreference === 'string';
    if (hasPersistedPreference) {
      return persistedColorPreference;
    }
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    const hasMediaQueryPreference = typeof mql.matches === 'boolean';
    if (hasMediaQueryPreference) {
      return mql.matches ? 'dark' : 'light';
    }
  }
  return defaultMode;
};

export const getCSSThemeMode = () => (typeof window !== 'undefined') ? window.getComputedStyle(document.documentElement).getPropertyValue('--theme-mode') : 'light';
