import React from 'react';
import styled, { css } from 'styled-components';
import { pluckResponsiveSizing, pluckColorFromTheme } from 'shared-ui/src/utils/utils';
import { Box } from 'shared-ui';

const colorStyles = ({theme, attrs: { type }}) => {
  const statusBgColor = pluckColorFromTheme(theme, `status-${type}`);
  const statusOverlayColor = pluckColorFromTheme(theme, `status-overlay-${type}`);
  return css`
    background-color: ${statusBgColor};
    color: ${statusOverlayColor};
  `;
};

export const StyledAlertBox = styled(Box)`
  ${props => colorStyles(props)}
`;

export const StyledAlertTitle = styled.h4`
  margin-bottom: ${({theme}) => pluckResponsiveSizing(theme, 'spacing', 'sm')};
`;

export const StyledAlertMessage = styled.div`
  
`;
