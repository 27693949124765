import React from 'react';
import { BaseStyles } from 'shared-ui/src/utils/styles';

export default ({ element, props }) => {
  return (
    <>
      <BaseStyles/>
      {element}
    </>
  );
}
