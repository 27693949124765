import PropTypes from 'prop-types';

export const sizePropType = PropTypes.oneOf(['sm', 'md', 'lg']);

export const spacingPropType = PropTypes.oneOfType([
  PropTypes.oneOf(['sm', 'md', 'lg', 'default', 'base']),
  PropTypes.object,
]);

export const elevationPropType = PropTypes.oneOf(['none', 'xs', 'sm', 'lg', 'xl', 'md', 'default', 'base', true, false]);

export const roundedPropType = PropTypes.oneOf(['default', 'base', 'md', 'sm', 'lg', 'full', true, false]);

export const headingLevelPropType = PropTypes.oneOf([1, 2, 3, 4, 5, 6]);

export const colorPropType = PropTypes.oneOf([
  'default',
  'brand',
  'accent-1',
  'accent-2',
  'accent-3',
  'accent-4',
  'accent-5'
]);

export const statusPropType = PropTypes.oneOf([
  'success',
  'error',
  'warning',
  'info'
]);

export const stringOrComponentPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.func
]);

export const iconPropType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.func
]);

export const inputComponentPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);

export const defaultPropTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};
