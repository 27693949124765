import { getKeyByValue } from './objects';
import {
  DEVICE_COMPUTER,
  DEVICE_MOBILE,
  DEVICE_TABLET
} from '../components/helpers/ThemeProvider/constants';

export const getBreakpoint = theme => {
  const windowWidth =
    typeof window !== 'undefined'
      ? window.innerWidth
      : theme.global.deviceBreakpoints.tablet;

  let result;
  Object.keys(theme.global.breakpoints)
    .sort((a, b) => {
      const first = theme.global.breakpoints[a];
      const second = theme.global.breakpoints[b];
      if (!first) return 1;
      if (!second) return -1;
      if (!first.value) return 1;
      if (!second.value) return -1;
      return first.value - second.value;
    })
    .some(name => {
      const breakpoint = theme.global.breakpoints[name];
      if (breakpoint) {
        if (!breakpoint.value || breakpoint.value >= windowWidth) {
          result = name;
          return true;
        }
      }
      return false;
    });

  return result;
};

export const getResponsiveDeviceVars = (breakpoint, theme) => {
  const device = getKeyByValue(theme.global.deviceBreakpoints, breakpoint);
  return {
    isMobile: device === DEVICE_MOBILE || device === DEVICE_TABLET,
    isMobileOnly: device === DEVICE_MOBILE,
    isTablet: device === DEVICE_TABLET,
    isComputer: device === DEVICE_COMPUTER,
    isComputerOrTablet: device === DEVICE_COMPUTER || device === DEVICE_TABLET
  };
};

export const getDeviceBreakpoint = (device, theme) =>
  theme.global.deviceBreakpoints[device];
