import { css } from 'bumbag/styled'
import { getRGBA } from 'shared-ui/src/utils/colors';

const palette = {
  text: '#101538',
  defaultInverted: '#101538',
  defaultHoverBackground: 'rgba(0, 0, 0, 0.05)',
  primary: '#5a2ef8',
  primary100: getRGBA('#5a2ef8', 0.1),
  primaryInverted: '#FE8CDF',
  primaryTint: getRGBA('#5a2ef8', 0.5),
  primary600: '#6e3afb',
  primaryTintInverted: getRGBA('#FE8CDF', 0.5),
  secondary: '#75fbe6',
  secondary600: '#90ffd8',
  secondary800: getRGBA('#75fbe6', 0.92),
  secondaryInverted: '#1231e4',
  secondaryTint: getRGBA('#75fbe6', 0.5),
  secondaryTintInverted: getRGBA('#1231e4', 0.5),
  info: '#1e67d5',
  success: '#0de48f',
  //successTint: '#90ffd8',
  //successTintInverted: '#000',
  //successShade: '#90ffd8',
  danger: '#da1717',
  dangerTint: getRGBA('#da1717', 0.25),
  warning: '#ed9c22',
  background: '#ededed',
  highlight: '#5a2ef8',
  link: '#5a2ef8',
  linkHover: '#1231e4',
  linkHoverBackground: '#75fbe6',
  pill: '#101538',
  pillHover: '#5a2ef8',
  pillFocus: '#5a2ef8',
  pillHoverBackground: getRGBA('#5a2ef8', 0.1),
  inputText: '#101538',
  inputTextPlaceholder: '#919aa9',
  modes: {
    night: {
      defaultInverted: '#f8f8f8',
      defaultHoverBackground: 'rgba(255, 255, 255, 0.05)',
      highlight: '#75fbe6',
      link: '#75fbe6',
      linkHover: '#1231e4',
      linkHoverBackground: '#75fbe6',
      background: '#101538',
      text: '#f8f8f8',
      pill: '#f8f8f8',
      pillHover: '#1231e4',
      pillFocus: '#75fbe6',
      pillHoverBackground: getRGBA('#75fbe6', 0.92),
      inputText: '#101538',
      gray100: '#121741',
      gray200: '#14194b',
      gray300: '#141955',
      gray400: '#141a5f',
      gray: '#141c69',
      gray500: '#0f142e',
      gray600: '#101225',
      gray700: '#0d0d1b',
      gray800: '#080811',
      gray900: '#030307'
    }
  }
  /*
  background: string,
  black: string,
  white: string,
  white600: string,
  white700: string,
  white800: string,
  white900: string,
  gray100: string,
  gray200: string,
  gray300: string,
  gray400: string,
  gray: string,
  gray500: string,
  gray600: string,
  gray700: string,
  gray800: string,
  gray900: string,
  default: string,
  defaultInverted: string,
  primaryTint: string,
  primaryTintInverted: string,
  primary100: string,
  primary200: string,
  primary300: string,
  primary400: string,
  primary: string,
  primaryInverted: string,
  primary500: string,
  primary600: string,
  primary700: string,
  primary800: string,
  primary900: string,
  secondaryTint: string,
  secondaryTintInverted: string,
  secondary100: string,
  secondary200: string,
  secondary300: string,
  secondary400: string,
  secondary: string,
  secondaryInverted: string,
  secondary500: string,
  secondary600: string,
  secondary700: string,
  secondary800: string,
  secondary900: string,
  infoTint: string,
  infoTintInverted: string,
  info100: string,
  info200: string,
  info300: string,
  info400: string,
  info: string,
  infoInverted: string,
  info500: string,
  info600: string,
  info700: string,
  info800: string,
  info900: string,
  successTint: string,
  successTintInverted: string,
  success100: string,
  success200: string,
  success300: string,
  success400: string,
  success: string,
  successInverted: string,
  success500: string,
  success600: string,
  success700: string,
  success800: string,
  success900: string,
  dangerTint: string,
  dangerTintInverted: string,
  danger100: string,
  danger200: string,
  danger300: string,
  danger400: string,
  danger: string,
  dangerInverted: string,
  danger500: string,
  danger600: string,
  danger700: string,
  danger800: string,
  danger900: string,
  warningTint: string,
  warningTintInverted: string,
  warning100: string,
  warning200: string,
  warning300: string,
  warning400: string,
  warning: string,
  warningInverted: string,
  warning500: string,
  warning600: string,
  warning700: string,
  warning800: string,
  warning900: string,
  // Plus your own definitions...
  [key: string]: string,
  // e.g. fullySickBlue: '#145abc'
  */
};

export const headerHeight = 60;

const theme = {
  modes: {
    useSystemColorMode: false
  },
  palette,
  fonts: {
    default: "'Inter', -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif",
    heading: "'Inter', -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif",
    mono: "Menlo, Courier, monospace"
  },
  altitudes: {
    100: 'box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 1px rgba(0, 0, 0, 0.02)',
    200: 'box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15), 0px 0px 4px 2px rgba(0, 0, 0, 0.02)',
    300: 'box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 6px 3px rgba(0, 0, 0, 0.02)',
    400: 'box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.15), 0px 0px 12px 6px rgba(0, 0, 0, 0.02)',
    700: 'box-shadow: 0px 24px 72px 0px rgba(0, 0, 0, 0.15), 0px 0px 24px 72px rgba(0, 0, 0, 0.02)',
  },
  spacing: {
    //none: 0,
    //extraSmall: 4
  },
  global: {
    fontSize: 17,
    styles: {
      base: css`
        html,
        body {
          
        }
        
        .no-hover {
          &:hover {
            background-color: transparent!important;
            .bb-TopNavItem {
              background-color: transparent!important;
            }
          }
        }
        
        body.bb-mode-default {
          .logo-light {
            display: block;
          }
          .logo-dark {
            display: none;
          }
        }
        
        body.bb-mode-night {
          .logo-light {
            display: none;
          }
          .logo-dark {
            display: block;
          }
        }
        
        .bb-ButtonClose {
          &:hover {
            background-color: var(--bb-palette-defaultHoverBackground);
          }
        }
      `
    }
  },
  Button: {
    styles: {
      base: {
        transition: 'box-shadow 0s ease-in-out 0s,border 0s,background-color 0s'
      }
    },
    variants: {
      'pill': {
        styles: {
          base: {
            boxShadow: 'none',
            border: 'none',
            color: 'pill',
            backgroundColor: 'transparent'
          },
          focus: {
            color: 'pillHover',
            backgroundColor: 'pillHoverBackground'
          },
          hover: {
            color: 'pillHover',
            backgroundColor: 'pillHoverBackground'
          },
          hoveractive: {
            color: 'pillHover',
            backgroundColor: 'pillHoverBackground'
          }
        },
        defaultProps: {
          palette: 'default',
          size: 'medium'
        }
      }
    }
  },
  Link: {
    styles: {
      base: {
        color: 'link',
        padding: '3px 2px'
      },
      hover: {
        textDecoration: 'none',
        color: 'linkHover',
        backgroundColor: 'linkHoverBackground'
      },
      focus: {
        textDecoration: 'none',
        color: 'linkHover'
      }
    }
  },
  TopNav: {
    Item: {
      styles: {
        base: {
          color: 'pill'
        },
        hover: {
          color: 'pillHover',
          backgroundColor: 'pillHoverBackground'
        },
        active: {
          color: 'pillHover',
          backgroundColor: 'pillHoverBackground'
        },
        focus: css`
          color: var(--bb-palette-pillFocus);
          &:hover {
            color: var(--bb-palette-pillHover);
          }
        `
      }
    }
  },
  Input: {
    styles: {
      base: {
        color: 'inputText'
      },
      placeholder: {
        color: 'inputTextPlaceholder'
      }
    }
  },
  FieldWrapper: {
    ValidationText: {
      styles: {
        base: {
          position: 'absolute'
        }
      }
    }
  },
  Alert: {
    styles: {
      base: {

      }
    },
    variants: {
      'success': {
        styles: {
          base: {
            backgroundColor: 'transparent'
          }
        },
        defaultProps: {
          type: 'success'
        }
      }
    }
  }
};

export default theme;
