import React, { forwardRef, useState } from 'react';
import {
  StyledBox
} from 'shared-ui/src/components/ui/Box/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-pro-regular/faTimes';
import { StyledBoxChildren, StyledBoxCloseIcon, StyledBoxIcon } from './styles';

const BoxComponent = forwardRef(
  (
    {
      children,
      vfull,
      withHeader,
      vcenter,
      rounded,
      center,
      elevation,
      spacing,
      padding,
      icon,
      onClose,
      closable,
      ...rest},
    ref
  ) => {
    const [open, setOpen] = useState(true);
    const isIcon = (icon !== null && icon !== undefined);
    const attrs = {vfull, withHeader, vcenter, center, elevation, spacing, padding, closable, isIcon, rounded};
    const elements = (isIcon) ? <StyledBoxChildren>{children}</StyledBoxChildren> : children;
    if(!open) return null;
    return (
      <StyledBox ref={ref} {...rest} attrs={attrs}>
        {isIcon && <StyledBoxIcon>{icon}</StyledBoxIcon>}
        {elements}
        {closable && <StyledBoxCloseIcon onClick={() => {
          setOpen(false);
          onClose();
        }}><FontAwesomeIcon icon={faTimes}/></StyledBoxCloseIcon>}
      </StyledBox>
    );
  }
);

export default BoxComponent;
