import PropTypes from 'prop-types';
import Heading from './component';
import {
  defaultPropTypes,
  sizePropType,
  headingLevelPropType, spacingPropType
} from '../propTypes';

Heading.defaultProps = {
  level: 2,

  spacing: { bottom: 'sm' }
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  spacing: spacingPropType,
  ...defaultPropTypes
};

export default Heading;
