import styled, { css } from 'styled-components';
import { sizeToStyle, pluckBreakpointFromThemeObject, pluckResponsiveSizing } from 'shared-ui/src/utils/utils';

const containerStyles = ({attrs: { center, flush, type }, theme}) => {
  const { container, responsive } = theme;
  const sizeObj = pluckBreakpointFromThemeObject(container[type], responsive);
  const spacing = (!flush) ? pluckResponsiveSizing(theme, 'spacing', 'base') : 0;
  return css`
    padding-left: ${spacing};
    padding-right: ${spacing};
    margin-left: ${center ? `auto` : 0};
    margin-right: ${center ? `auto` : 0};
    ${sizeObj.maxWidth && css`max-width: ${sizeToStyle(sizeObj.maxWidth)}`};
    ${sizeObj.width && css`width: ${sizeToStyle(sizeObj.width)}`};
  `;
};

export const StyledContainer = styled.div`
  ${props => containerStyles(props)}
`;
