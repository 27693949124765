import React, { forwardRef } from 'react';
import {
  StyledAlertMessage,
  StyledAlertTitle,
  StyledAlertBox
} from './styles';

const AlertComponent = forwardRef(
  (
    {
      type,
      message,
      title,
      icon,
      padding,
      spacing,
      closable,
      onClose,
      elevation,
      rounded,
      className,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledAlertBox
        rounded={rounded}
        elevation={elevation}
        closable={closable}
        padding={padding}
        spacing={spacing}
        onClose={onClose}
        className={className}
        style={style}
        icon={icon}
        ref={ref}
        attrs={{type, padding}}
      >
        {title && <StyledAlertTitle>{title}</StyledAlertTitle>}
        {message && <StyledAlertMessage>{message}</StyledAlertMessage>}
      </StyledAlertBox>
    );
  }
);

export default AlertComponent;
