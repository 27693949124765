import { deepFreeze } from '../utils/objects';
import { DEVICE_COMPUTER, DEVICE_MOBILE, DEVICE_TABLET } from '../components/helpers/ThemeProvider/constants';
import { getRGBA } from '../utils/colors';
import LogoLight from 'shared-assets/images/svgs/logo-light.svg';
import LogoDark from 'shared-assets/images/svgs/logo-dark.svg';
import LogoLightOpen from 'shared-assets/images/svgs/logo-light-open.svg';
import LogoDarkOpen from 'shared-assets/images/svgs/logo-dark-open.svg';

export const themeColors = {
  ['light-1']: '#F8F8F8',
  ['light-2']: '#F2F2F2',
  ['light-3']: '#EDEDED',
  ['light-4']: '#DADADA',
  ['light-5']: '#DADADA',
  ['light-6']: '#DADADA',
  ['dark-1']: '#333333',
  ['dark-2']: '#555555',
  ['dark-3']: '#777777',
  ['dark-4']: '#999999',
  ['dark-5']: '#999999',
  ['dark-6']: '#999999',
  ['status-critical']: '#de2020',
  ['status-error']: '#de2020',
  ['status-info']: '#e32dd6',
  ['status-warning']: '#ffd52f',
  ['status-success']: '#90ffd8',
  ['status-unknown']: '#7999a9',
  ['status-disabled']: '#7999a9',
  ['status-overlay-critical']: '#caffa9',
  ['status-overlay-error']: '#caffa9',
  ['status-overlay-info']: '#ffffff',
  ['status-overlay-warning']: '#ce149c',
  ['status-overlay-success']: '#1231e4',
  ['status-overlay-unknown']: '#e7ff20',
  ['status-overlay-disabled']: '#e7ff20',
  ['brand']: '#5a2ef8',
  ['accent-1']: '#75fbe6',
  ['accent-2']: '#416dea',
  ['accent-3']: '#e32dd6',
  ['accent-4']: '#ffd52f',
  ['accent-5']: '#7999a9',
  ['brand-hover']: '#6e3afb',
  ['accent-hover-1']: '#90ffd8',
  ['accent-hover-2']: '#416dea',
  ['accent-hover-3']: '#e32dd6',
  ['accent-hover-4']: '#ffd52f',
  ['accent-hover-5']: '#7999a9',
  ['brand-overlay']: '#FE8CDF',
  ['accent-overlay-1']: '#1231e4',
  ['accent-overlay-2']: '#ffffff',
  ['accent-overlay-3']: '#ffffff',
  ['accent-overlay-4']: '#ce149c',
  ['accent-overlay-5']: '#e7ff20',
  ['brand-hover-overlay']: '#FE8CDF',
  ['accent-hover-overlay-1']: '#1231e4',
  ['accent-hover-overlay-2']: '#ffffff',
  ['accent-hover-overlay-3']: '#ffffff',
  ['accent-hover-overlay-4']: '#ce149c',
  ['accent-hover-overlay-5']: '#e7ff20',
  ['active']: 'rgba(221, 221, 221, 0.5)',
  ['background-back']: {
    dark: '#101538',
    light: '#EDEDED'
  },
  ['background-front']: {
    dark: '#444444',
    light: '#FFFFFF'
  },
  ['background-contrast']: {
    dark: '#33333308',
    light: '#FFFFFF08'
  },
  ['active-background']: {
    dark: '#33333308',
    light: '#FFFFFF08'
  },
  ['active-text']: {
    dark: '#FFFFFF',
    light: '#000000'
  },
  ['black']: '#000000',
  ['border']: {
    dark: 'rgba(255, 255, 255, 0.15)',
    light: 'rgba(0, 0, 0, 0.08)'
  },
  ['control']: {
    dark: '#5a2ef8',
    light: '#5a2ef8'
  },
  ['focus']: '#75fbe6',
  ['placeholder']: '#AAAAAA',
  ['selected']: '#5a2ef8',
  ['text']: {
    dark: '#f8f8f8',
    light: '#101538'
  },
  ['text-strong']: {
    dark: '#FFFFFF',
    light: '#000000'
  },
  ['text-weak']: {
    dark: '#CCCCCC',
    light: '#555555'
  },
  ['text-xweak']: {
    dark: '#BBBBBB',
    light: '#9a9a9a'
  },
  ['icon']: {
    dark: '#f8f8f8',
    light: '#666666'
  },
  ['selected-background']: '#5a2ef8',
  ['selected-text']: {
    dark: '#FFFFFF',
    light: '#000000'
  },
  ['white']: '#FFFFFF'
};

export const globalStyles = {
  colors: themeColors,
  animation: {
    timing: {
      ['ease-base-out']: 'cubic-bezier(0.7, 0.3, 0.1, 1)',
      ['ease-base-in']: 'cubic-bezier(0.9, 0, 0.3, 0.7)',
      ['ease-out']: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      ['ease-in']: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      ['ease-in-out']: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      ['ease-out-back']: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
      ['ease-in-back']: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
      ['ease-in-out-back']: 'cubic-bezier(0.71, -0.46, 0.29, 1.46)',
      ['ease-out-circ']: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
      ['ease-in-circ']: 'cubic-bezier(0.6, 0.04, 0.98, 0.34)',
      ['ease-in-out-circ']: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      ['ease-out-quint']: 'cubic-bezier(0.23, 1, 0.32, 1)',
      ['ease-in-quint']: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      ['ease-in-out-quint']: 'cubic-bezier(0.86, 0, 0.07, 1)',
    },
    duration: {
      fast: '0.05s',
      base: '0.1s',
      slow: '0.25s'
    },
  },
  screen: {
    xs: '420px',
    sm: '568px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px'
  },
  spacing: {
    none: '0',
    xxs: '4px',
    xs: '8px',
    sm: '16px',
    base: '24px',
    lg: '32px',
    xl: '48px',
    xxl: '64px',
  },
  fontSize: {
    sm: '15px',
    base: '17px',
    lg: '24px'
  },
  borderRadius: {
    none: 'none',
    sm: '4px',
    base: '6px',
    lg: '12px',
    full: '50%'
  },
  borderSize: {
    none: '0',
    hair: '1px',
    sm: '2px',
    base: '2px',
    lg: '4px'
  },
  breakpoints: {
    sm: {
      value: 768,
      spacing: {
        none: '0',
        xxs: '4px',
        xs: '8px',
        sm: '12px',
        base: '16px',
        lg: '24px',
        xl: '32px',
        xxl: '64px',
      },
      fontSize: {
        sm: '14px',
        base: '16px',
        lg: '22px'
      },
    },
    md: {
      value: 1200
    },
    lg: {

    }
  },
  deviceBreakpoints: {
    [DEVICE_MOBILE]: 'sm',
    [DEVICE_TABLET]: 'md',
    [DEVICE_COMPUTER]: 'lg'
  },
  elevation: {
    light: {
      none: 'none',
      xs: '0px 1px 2px rgba(0, 0, 0, 0.15)',
      sm: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      base: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      lg: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
      xl: '0px 12px 24px rgba(0, 0, 0, 0.15)'
    },
    dark: {
      none: 'none',
      xs: '0px 1px 2px rgba(0, 0, 0, 0.15)',
      sm: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      base: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      lg: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
      xl: '0px 12px 24px rgba(0, 0, 0, 0.15)'
    }
  },
  opacity: {
    strong: 0.8,
    base: 0.4,
    weak: 0.1
  },
  controls: {
    size: {
      sm: {
        height: '38px',
        horizontalPadding: '16px',
        borderRadius: '24px',
        borderWidth: '2px',
        borderStyle: 'solid'
      },
      base: {
        height: '52px',
        horizontalPadding: '24px',
        borderRadius: '7px',
        borderWidth: '2px',
        borderStyle: 'solid'
      },
      lg: {
        height: '58px',
        horizontalPadding: '24px',
        borderRadius: '7px',
        borderWidth: '2px',
        borderStyle: 'solid'
      }
    }
  },
  font: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: {
      normal: '500',
      thick: '600'
    },
    textTransform: 'none',
    lineHeight: 1.5,
    letterSpacing: '0',
    paragraph: {
      marginBottom: '0.5em'
    }
  },
  link: {
    default: {
      color: {
        light: themeColors['brand'],
        dark: themeColors['accent-1']
      }
    },
    hover: {
      color: {
        light: themeColors['brand-hover'],
        dark: themeColors['accent-hover-1']
      }
    }
  },
  paragraph: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: {
      normal: '500',
      thick: '600'
    },
    paragraph: {
      marginBottom: '0.75em'
    },
    textTransform: 'none',
    lineHeight: 1.75,
    letterSpacing: '0',
    fontSize: {
      sm: {
        sm: '13.5px',
        base: '17px',
        lg: '24px'
      },
      base: {
        sm: '13.5px',
        base: '17px',
        lg: '24px'
      },
    }
  },
  layout: {
    header: {
      height: 65
    },
  }
};

export default deepFreeze({
  global: globalStyles,
  heading: {
    fontFamily: "'Inter', sans-serif",
    fontWeight: '700',
    textTransform: 'none',
    lineHeight: '1.25',
    letterSpacing: '0',
    marginTop: '1.2em',
    marginBottom: '0.5em',
    level: {
      0: {
        fontSize: {
          sm: '36px',
          md: '50px'
        },
      },
      1: {
        fontSize: {
          sm: '36px',
          md: '40px'
        },
      },
      2: {
        fontSize: {
          sm: '32px',
          md: '36px'
        },
      },
      3: {
        fontSize: {
          sm: '24px',
          md: '28px'
        },
      },
      4: {
        //textTransform: 'uppercase',
        fontSize: {
          sm: '18px',
          md: '22px'
        },
      },
      5: {
        textTransform: 'uppercase',
        fontSize: {
          sm: '20px',
          md: '24px'
        },
      },
      6: {
        textTransform: 'uppercase',
        fontSize: {
          sm: '18px',
          md: '20px'
        },
      }
    }
  },
  button: {
    /*
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.base,
      properties: ['color', 'background-color', 'border-color', 'box-shadow']
    },*/
    size: {
      sm: {
        textTransform: 'none',
        textShadow: 'none',
        fontWeight: '700',
        fontSize: '15px',
      },
      base: {
        textTransform: 'none',
        textShadow: 'none',
        fontWeight: '700',
        fontSize: '17px',
      },
      lg: {
        textTransform: 'none',
        textShadow: 'none',
        fontWeight: '700',
        fontSize: '17px'
      }
    },
    type: {
      ['default']: {
        default: {
          background: themeColors['light-3'],
          borderColor: themeColors['light-3'],
          color: themeColors['text']['light']
        },
        hover: {
          background: themeColors['light-5'],
          borderColor: themeColors['light-5'],
          color: themeColors['text']['light']
        }
      },
      ['brand']: {
        default: {
          background: themeColors['brand'],
          borderColor: themeColors['brand'],
          color: themeColors['brand-overlay']
        },
        hover: {
          background: themeColors['brand-hover'],
          borderColor: themeColors['brand-hover'],
          color: themeColors['brand-hover-overlay']
        }
      },
      ['accent-1']: {
        default: {
          background: themeColors['accent-1'],
          borderColor: themeColors['accent-1'],
          color: themeColors['accent-overlay-1']
        },
        hover: {
          background: themeColors['accent-hover-1'],
          borderColor: themeColors['accent-hover-1'],
          color: themeColors['accent-hover-overlay-1']
        }
      },
      ['accent-2']: {
        default: {
          background: themeColors['accent-2'],
          borderColor: themeColors['accent-2'],
          color: themeColors['accent-overlay-2']
        },
        hover: {
          background: themeColors['accent-hover-2'],
          borderColor: themeColors['accent-hover-2'],
          color: themeColors['accent-hover-overlay-2']
        }
      },
      ['accent-3']: {
        default: {
          background: themeColors['accent-3'],
          borderColor: themeColors['accent-3'],
          color: themeColors['accent-overlay-3']
        },
        hover: {
          background: themeColors['accent-hover-3'],
          borderColor: themeColors['accent-hover-3'],
          color: themeColors['accent-hover-overlay-3']
        }
      },
      ['accent-4']: {
        default: {
          background: themeColors['accent-4'],
          borderColor: themeColors['accent-4'],
          color: themeColors['accent-overlay-4']
        },
        hover: {
          background: themeColors['accent-hover-4'],
          borderColor: themeColors['accent-hover-4'],
          color: themeColors['accent-hover-overlay-4']
        }
      },
      ['accent-5']: {
        default: {
          background: themeColors['accent-5'],
          borderColor: themeColors['accent-5'],
          color: themeColors['accent-overlay-5']
        },
        hover: {
          background: themeColors['accent-hover-5'],
          borderColor: themeColors['accent-hover-5'],
          color: themeColors['accent-hover-overlay-5']
        }
      },
    },
  },
  input: {
    size: {
      sm: {
        fontWeight: '500',
        fontSize: '15px',
      },
      base: {
        fontWeight: '500',
        fontSize: '17px',
      },
      lg: {
        fontWeight: '500',
        fontSize: '17px',
      }
    },
    light: {
      borderColor: themeColors['brand'],
      focusBorderColor: themeColors['accent-1'],
      color: themeColors['text']['light'],
      background: themeColors['white'],
      placeholder: {
        color: themeColors['text-xweak']['light']
      }
    },
    dark: {
      borderColor: themeColors['brand'],
      focusBorderColor: themeColors['accent-1'],
      color: themeColors['text']['light'],
      background: themeColors['white'],
      placeholder: {
        color: themeColors['text-xweak']['light']
      }
    }
  },
  container: {
    default: {
      sm: {
        maxWidth: '100%',
        width: '100%'
      },
      md: {
        maxWidth: 992,
        width: '100%'
      },
      lg: {
        maxWidth: 1200,
        width: '100%'
      }
    },
    narrow: {
      sm: {
        maxWidth: 768,
        width: '100%'
      },
      md: {
        maxWidth: 768,
        width: '100%'
      }
    },
    tiny: {
      sm: {
        width: '100%',
        maxWidth: 450
      }
    },
    half: {
      sm: {
        width: '100%'
      },
      md: {
        width: '50%'
      }
    },
    fluid: {
      sm: {
        width: '100%'
      }
    }
  },
  switch: {
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.slow,
      properties: ['all']
    },
    checked: {
      background: {
        light: themeColors['brand'],
        dark: themeColors['brand'],
      }
    },
    unchecked: {
      background: {
        light: themeColors['light-5'],
        dark: themeColors['light-5']
      }
    },
    slider: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white']
      },
      width: 26,
      height: 26,
    },
    width: 60,
    height: 34,
    translate: 26
  },
  checkbox: {
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.base,
      properties: ['all']
    },
    width: 19,
    height: 19,
    borderRadius: globalStyles.borderRadius.sm,
    borderWidth: globalStyles.borderSize.base,
    focusShadowWidth: '3px',
    arrowStrokeWidth: '3px',
    checked: {
      background: {
        light: themeColors['brand'],
        dark: themeColors['brand'],
      },
      borderColor: {
        light: themeColors['brand'],
        dark: themeColors['brand'],
      },
      focusShadowColor: {
        light: getRGBA(themeColors['brand'], 0.2),
        dark: getRGBA(themeColors['brand'], 0.2)
      }
    },
    unchecked: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white']
      },
      borderColor: {
        light: themeColors['brand'],
        dark: themeColors['brand'],
      },
      focusShadowColor: {
        light: getRGBA(themeColors['brand'], 0.2),
        dark: getRGBA(themeColors['brand'], 0.2)
      }
    }
  },
  tag: {
    fontWeight: '600',
    fontSize: '13px',
    color: {
      ['default']: {
        background: themeColors['light-3'],
        borderColor: themeColors['light-3'],
        color: themeColors['text']['light']
      },
      ['primary']: {
        background: themeColors['brand'],
        borderColor: themeColors['brand'],
        color: themeColors['brand-overlay']
      },
      ['accent-1']: {
        background: themeColors['accent-1'],
        borderColor: themeColors['accent-1'],
        color: themeColors['accent-overlay-1']
      },
      ['accent-2']: {
        background: themeColors['accent-2'],
        borderColor: themeColors['accent-2'],
        color: themeColors['accent-overlay-2']
      },
      ['accent-3']: {
        background: themeColors['accent-3'],
        borderColor: themeColors['accent-3'],
        color: themeColors['accent-overlay-3']
      },
      ['accent-4']: {
        background: themeColors['accent-4'],
        borderColor: themeColors['accent-4'],
        color: themeColors['accent-overlay-4']
      },
      ['accent-5']: {
        background: themeColors['accent-5'],
        borderColor: themeColors['accent-5'],
        color: themeColors['accent-overlay-5']
      },
    },
  },
  table: {
    theme: {
      light: {
        text: {
          primary: themeColors['text']['light'],
          secondary: themeColors['text-weak']['light'],
          disabled: themeColors['text-xweak']['light'],
        },
        background: {
          default: themeColors['background-back']['light'],
        },
        context: {
          background: '#e3f2fd',
          text: 'rgba(0, 0, 0, 0.87)',
        },
        divider: {
          default: themeColors['border']['light'],
        },
        button: {
          default: 'rgba(0,0,0,.54)',
          focus: 'rgba(0,0,0,.12)',
          hover: 'rgba(0,0,0,.12)',
          disabled: 'rgba(0, 0, 0, .18)',
        },
        sortFocus: {
          default: 'rgba(0, 0, 0, .54)',
        },
        selected: {
          default: '#e3f2fd',
          text: 'rgba(0, 0, 0, 0.87)',
        },
        highlightOnHover: {
          default: 'rgba(0, 0, 0, 0.05)',
          text: themeColors['text']['light'],
        },
        striped: {
          default: '#FAFAFA',
          text: 'rgba(0, 0, 0, 0.87)',
        },
      },
      dark: {
        text: {
          primary: themeColors['text']['dark'],
          secondary: themeColors['text-weak']['dark'],
          disabled: themeColors['text-xweak']['dark'],
        },
        background: {
          default: themeColors['background-back']['dark'],
        },
        context: {
          background: '#E91E63',
          text: '#FFFFFF',
        },
        divider: {
          default: themeColors['border']['dark'],
        },
        button: {
          default: '#FFFFFF',
          focus: 'rgba(255, 255, 255, .54)',
          hover: 'rgba(255, 255, 255, .12)',
          disabled: 'rgba(255, 255, 255, .18)',
        },
        sortFocus: {
          default: 'rgba(255, 255, 255, .54)',
        },
        selected: {
          default: 'rgba(0, 0, 0, .7)',
          text: '#FFFFFF',
        },
        highlightOnHover: {
          default: 'rgba(255, 255, 255, 0.05)',
          text: '#FFFFFF',
        },
        striped: {
          default: 'rgba(0, 0, 0, .87)',
          text: '#FFFFFF',
        },
      }
    },
    styles: {
      header: {
        style: {
          fontSize: '22px',
          minHeight: '56px',
          paddingLeft: '16px',
          paddingRight: '8px',
        },
      },
      subHeader: {
        style: {
          paddingLeft: '0',
          paddingRight: '0',
          minHeight: '52px',
        },
      },
      head: {
        style: {},
      },
      headRow: {
        style: {
          minHeight: '56px',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
        },
        denseStyle: {
          minHeight: '32px',
        },
      },
      headCells: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          paddingLeft: '16px',
          paddingRight: '16px',
        }
      },
      contextMenu: {
        style: {
          fontSize: '18px',
          fontWeight: 600,
          paddingLeft: '16px',
          paddingRight: '8px',
          transform: 'translate3d(0, -100%, 0)',
          transitionDuration: '125ms',
          transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
          willChange: 'transform',
        },
        activeStyle: {
          transform: 'translate3d(0, 0, 0)',
        },
      },
      cells: {
        style: {
          paddingLeft: '16px',
          fontWeight: '600!important',
          paddingRight: '16px',
          wordBreak: 'break-word',
          overflow: 'hidden'
        },
      },
      rows: {
        style: {
          fontSize: '14px',
          minHeight: '48px',
          '&:not(:last-of-type)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px'
          },
        },
        denseStyle: {
          minHeight: '32px',
        },

        highlightOnHoverStyle: {
          transitionDuration: '0s',
          transitionProperty: 'background-color',
          outlineStyle: 'solid',
          outlineWidth: '1px',
        }
      },
      expanderCell: {
        style: {
          flex: '0 0 48px',
        },
      },
      expanderButton: {
        style: {
          backgroundColor: 'transparent',
          borderRadius: '2px',
          transition: '0.25s',
          height: '100%',
          width: '100%'
        },
      },
      pagination: {
        style: {
          fontSize: '14px',
          fontWeight: '600!important',
          minHeight: '56px',
          borderTopStyle: 'solid',
          borderTopWidth: '1px'
        },
        pageButtonsStyle: {
          borderRadius: '50%',
          height: '40px',
          width: '40px',
          padding: '8px',
          margin: 'px',
          cursor: 'pointer',
          transition: '0.4s',
          backgroundColor: 'transparent'
        },
      },
      noData: {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
      progress: {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
    }
  },
  drawer: {
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.slow,
      properties: ['transform', 'opacity']
    },
    theme: {
      light: {
        background: themeColors['background-back']['light']
      },
      dark: {
        background: themeColors['background-back']['dark']
      }
    }
  },
  select: {
    styles: {
      input: {},
      select: {},
      loader: {
        size: '0.625rem',
        padding: '0.375rem 0.75rem',
        animation: 'BOUNCE_KEYFRAMES 1.19s ease-in-out infinite'
      },
      icon: {
        padding: '0 0.9375rem',
        clear: {
          width: '14px',
          height: '16px',
          animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
          transition: 'color 0.15s ease-in-out'
        },
        caret: {
          size: '7px',
          transition: 'transform 0.25s ease-in-out, color 0.15s ease-in-out'
        }
      },
      menu: {
        width: '100%',
        margin: '0.5rem 0',
        padding: '0.15rem 0',
        borderRadius: globalStyles.borderRadius.base,
        //animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
        option: {
          textAlign: 'left',
          padding: '0.375rem 0.75rem',
        }
      },
      noOptions: {
        fontSize: '1rem',
        margin: '0.25rem 0',
        padding: '0.375rem 0.75rem'
      },
      multiValue: {
        borderRadius: '0.25rem',
        //animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
        label: {
          fontSize: '0.825em',
          borderRadius: '0.25rem'
        },
        clear: {
          fontWeight: 700,
          padding: '0 7px',
          fontSize: '0.67em',
          alignItems: 'center',
          borderRadius: '0.25rem',
          transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out'
        }
      }
    },
    theme: {
      light: {
        color: {
          border: themeColors['brand'],
          danger: themeColors['status-danger'],
          primary: themeColors['brand'],
          disabled: themeColors['status-disabled'],
          placeholder: themeColors['text-xweak']['light'],
          dangerLight: 'rgba(220, 53, 69, 0.25)'
        },
        input: {
          color: themeColors['text']['light']
        },
        loader: {
          color: themeColors['brand']
        },
        icon: {
          color: '#ccc',
          hoverColor: '#A6A6A6'
        },
        control: {
          boxShadowColor: 'transparent',
          focusedBorderColor: themeColors['accent-1']
        },
        menu: {
          backgroundColor: themeColors['white'],
          color: themeColors['text']['light'],
          boxShadow: `0 0 0 1px ${themeColors['border']['light']}, ${globalStyles.elevation['light'].base}`,
          option: {
            selectedColor: themeColors['accent-overlay-1'],
            selectedBgColor: themeColors['accent-1'],
            focusedColor: themeColors['text']['light'],
            focusedBgColor: themeColors['light-3']
          }
        },
        noOptions: {
          color: themeColors['text-weak']['light'],
        },
        multiValue: {
          backgroundColor: themeColors['light-3'],
          color: themeColors['text']['light']
        }
      },
      dark: {
        color: {
          border: themeColors['brand'],
          danger: themeColors['status-danger'],
          primary: themeColors['brand'],
          disabled: themeColors['status-disabled'],
          placeholder: themeColors['text-xweak']['light'],
          dangerLight: 'rgba(220, 53, 69, 0.25)'
        },
        input: {
          color: themeColors['text']['light']
        },
        loader: {
          color: themeColors['brand']
        },
        icon: {
          color: '#ccc',
          hoverColor: '#A6A6A6'
        },
        control: {
          boxShadowColor: 'transparent',
          focusedBorderColor: themeColors['accent-1']
        },
        menu: {
          backgroundColor: themeColors['white'],
          color: themeColors['text']['light'],
          boxShadow: `0 0 0 1px ${themeColors['border']['light']}, ${globalStyles.elevation['light'].base}`,
          option: {
            selectedColor: themeColors['accent-overlay-1'],
            selectedBgColor: themeColors['accent-1'],
            focusedColor: themeColors['text']['light'],
            focusedBgColor: themeColors['light-3']
          }
        },
        noOptions: {
          color: themeColors['text-weak']['light'],
        },
        multiValue: {
          backgroundColor: themeColors['light-3'],
          color: themeColors['text']['light']
        }
      }
    }
  },
  dropzone: {
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.base,
      properties: ['border']
    },
    style: {
      padding: globalStyles.spacing.base,
      fontSize: globalStyles.fontSize.base,
      borderWidth: globalStyles.borderSize.base,
      borderRadius: globalStyles.borderRadius.base,
      borderStyle: 'dashed'
    },
    accept: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white'],
      },
      borderColor: {
        light: themeColors['status-success'],
        dark: themeColors['status-success'],
      },
      color: {
        light: themeColors['text-weak']['light'],
        dark: themeColors['text-weak']['light'],
      }
    },
    reject: {
      background: {
        light: themeColors['brand'],
        dark: themeColors['brand']
      },
      borderColor: {
        light: themeColors['status-error'],
        dark: themeColors['status-error']
      },
      color: {
        light: themeColors['text-weak']['light'],
        dark: themeColors['text-weak']['light'],
      }
    },
    active: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white'],
      },
      borderColor: {
        light: themeColors['status-info'],
        dark: themeColors['status-info'],
      },
      color: {
        light: themeColors['text-weak']['light'],
        dark: themeColors['text-weak']['light'],
      }
    },
    hover: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white'],
      },
      borderColor: {
        light: themeColors['accent-3'],
        dark: themeColors['accent-3'],
      },
      color: {
        light: themeColors['text-weak']['light'],
        dark: themeColors['text-weak']['light'],
      }
    },
    resting: {
      background: {
        light: themeColors['white'],
        dark: themeColors['white'],
      },
      borderColor: {
        light: themeColors['brand'],
        dark: themeColors['brand'],
      },
      color: {
        light: themeColors['text-weak']['light'],
        dark: themeColors['text-weak']['light'],
      }
    }
  },
  loader: {
    icon: {
      color: {
        light: themeColors['brand-overlay'],
        dark: themeColors['brand-overlay'],
      }
    },
    overlay: {
      background: {
        light: getRGBA(themeColors['brand'], 0.5),
        dark: getRGBA(themeColors['brand'], 0.5)
      }
    },
  },
  logo: {
    text: 'Desk Warrior',
    width: 180,
    height: 46,
    image: {
      default: {
        light: LogoLightOpen,
        dark: LogoDark
      },
      hover: {
        light: LogoLight,
        dark: LogoDarkOpen
      }
    }
  },
  header: {
    fixed: false,
    height: globalStyles.layout.header.height,
    logoWidth: 180,
    logoHeight: 46,
    contained: true,
    shadowOnScroll: true,
    shadowScrollHeight: 100,
    showThemeSwitcher: true,
    mobileBreakpoint: 'md',
    styles: {
      background: {
        light: themeColors['background-back']['light'],
        dark: themeColors['background-back']['dark']
      },
      boxShadow: {
        light: globalStyles.elevation.light.base,
        dark: globalStyles.elevation.light.base
      },
      borderBottomColor: {
        light: themeColors['border']['light'],
        dark: themeColors['border']['dark'],
      },
      borderWidth: 0
    },

    menu: {
      lineHeight: 36,
      horizontalPadding: globalStyles.spacing.sm,
      horizontalMargin: '2px',
      css: `
        @media(min-width: 992px) {
          position:relative; top:3.5px;
        }
      `
    }
  },
  menu: {
    /*
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.base,
      properties: ['color']
    },*/
    lineHeight: 36,
    horizontalPadding: globalStyles.spacing.sm,
    horizontalMargin: '2px',
    verticalMargin: '4px',
    fontSize: globalStyles.fontSize.base,
    fontWeight: globalStyles.font.fontWeight.thick,
    color: {
      light: themeColors['text']['light'],
      dark: themeColors['text']['dark']
    },
    css: `border-radius: ${globalStyles.borderRadius.base};`,
    active: {
      color: {
        light: themeColors['brand'],
        dark: themeColors['brand']
      },
      css: {
        light: `background-color: ${getRGBA(themeColors['brand'], 0.12)};`,
        dark: `background-color: ${getRGBA(themeColors['accent-1'], 1)};`,
      }
    }
  },
  grid: {
    gap: globalStyles.spacing.base,
    columnGap: undefined,
    rowGap: undefined,
    minRowHeight: '20px'
  }
});
