import React, { forwardRef } from 'react';
import {
  StyledText,
} from './styles';

const TextComponent = forwardRef(
  (
    {
      as,
      children,
      spacing,
      paragraph,
      className,
      size,
      strength,
      style,
      text,
      ...rest
    },
    ref
  ) => {

    const extraProps = {};
    if(!children && text) {
      extraProps.dangerouslySetInnerHTML = { __html: text };
    }

    return (
      <StyledText
        attrs={{spacing, size, strength, paragraph}}
        ref={ref}
        as={as}
        {...extraProps}
      >
        {children}
      </StyledText>
    );
  }
);

export default TextComponent;
