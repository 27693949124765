import Container from 'shared-ui/src/components/ui/Container/component';
import { CONTAINER_SIZE_DEFAULT } from 'shared-ui/src/components/ui/Container/constants';

Container.defaultProps = {
  type: CONTAINER_SIZE_DEFAULT, // fluid, narrow, tiny
  center: true,
  flush: false
};

export default Container;
