import React, { forwardRef } from 'react';
import {
  StyledTagBox
} from './styles';

const TagComponent = forwardRef(
  (
    {
      color,
      status,
      children,
      icon,
      padding,
      spacing,
      closable,
      onClose,
      rounded,
      elevation,
      className,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledTagBox
        rounded={rounded}
        elevation={elevation}
        closable={closable}
        padding={padding}
        spacing={spacing}
        onClose={onClose}
        className={className}
        style={style}
        icon={icon}
        ref={ref}
        attrs={{color, status, padding}}
      >
        {children}
      </StyledTagBox>
    );
  }
);

export default TagComponent;
