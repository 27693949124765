import styled, { css } from 'styled-components';
import { removePx } from '../../../utils/strings';
import { transition } from '../../../utils/mixins';

export const StyledSwitchContainer = styled.label `
    display: flex;
    align-items: center;
`;

export const StyledSwitchInputContainer = styled.label`
    position: relative;
    display: inline-block;
    width: ${({theme: { switch: { width }}}) => removePx(width)}px;
    height: ${({theme: { switch: { height }}}) => removePx(height)}px;
    > input {
        display: none;
    }
`;

export const StyledSwitchInput = styled.input`
    &:checked + span {
       background: ${({theme: { mode, switch: { checked: { background }}}}) => background};
    }
    &:disabled + span {
        background: ${({theme: { mode, switch: { unchecked: { background }}}}) => background};
        opacity: ${({theme: { global: { opacity }}}) => opacity.base};
        cursor: not-allowed;
    }
    &:disabled:checked + span {
        background: ${({theme: { mode, switch: { checked: { background }}}}) => background};
        opacity: ${({theme: { global: { opacity }}}) => opacity.base};
        cursor: not-allowed;
    }
    &:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }
    &:checked + span:before {
        transform: translateX(${({theme: { switch: { translate }}}) => removePx(translate)}px);
    }
`;

export const StyledSwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    user-select:none;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({theme: { mode, switch: { unchecked: { background }}}}) => background};
    ${({theme}) => theme.switch.transition && transition(theme.switch.transition)}
    border-radius: 34px;
    &:before {
        position: relative;
        border-radius: 50%;
        z-index: 2;
        content: '';
        height: ${({theme: { switch: { slider: { height } }}}) => removePx(height)}px;
        width: ${({theme: { switch: { slider: { width } }}}) => removePx(width)}px;
        left: 4px;
        background: ${({theme: { mode, switch: { slider: { background }}}}) => background};
        ${({theme}) => theme.switch.transition && transition(theme.switch.transition)}
    }
`;

export const StyledSwitchSliderIcons = styled.span`
    position: absolute;
    top: 0;
    left: 2px;
    right: 2px;
    bottom: 0;
    z-index: 1;
    justify-content: space-between;
    display: flex;
    > div {
      width: ${({theme: { switch: { slider: { width } }}}) => removePx(width)}px;
      height: ${({theme: { switch: { height }}}) => removePx(height)}px;
      line-height: ${({theme: { switch: { height }}}) => removePx(height)}px;
      text-align: center;
    }
`;

export const StyledSwitchLabel = styled.span`
    padding: 0 10px;
`;
