import PropTypes from 'prop-types';
import Tag from './component';
import {
  colorPropType,
  defaultPropTypes, elevationPropType,
  iconPropType,
  spacingPropType,
  statusPropType
} from '../propTypes';

Tag.defaultProps = {
  status: null,
  color: 'default',
  padding: { top: 'xxs', bottom: 'xxs', left: 'xs', right: 'xs' },
  spacing: { right: 'xxs' },
  rounded: true,
  elevation: 'xs',
  onClose: () => null
};

Tag.propTypes = {
  status: statusPropType,
  color: colorPropType,
  icon: iconPropType,
  padding: spacingPropType,
  spacing: spacingPropType,
  elevation: elevationPropType,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  ...defaultPropTypes
};

export default Tag;
