import React from 'react';
import PropTypes from 'prop-types';
import ThemedComponent from './component';
import { withTheme } from 'styled-components';
import { withResponsive } from '../../../contexts/responsive';

ThemedComponent.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
  opposite: PropTypes.bool,
  includeBg: PropTypes.bool
};

ThemedComponent.defaultProps = {
  opposite: false,
  includeBg: true
};

export default withTheme(withResponsive(ThemedComponent));
