import React, { forwardRef } from 'react';
import {
  StyledHeading,
} from './styles';

const HeadingComponent = forwardRef(
  (
    {
      level,
      children,
      spacing,
      className,
      size,
      style,
      ...rest
    },
    ref
  ) => {

    return (
      <StyledHeading
        attrs={{spacing, size: (size !== undefined) ? size : level}}
        ref={ref}
        as={`h${level}`}
      >
        {children}
      </StyledHeading>
    );
  }
);

export default HeadingComponent;
