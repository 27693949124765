import React from 'react';
import styled, { css } from 'styled-components';
import {
  sanitizePropsWithBase
} from '../../../utils/utils';
import { marginSpacingStyle } from '../../../utils/styles';

const textStyles = ({attrs, theme}) => {

  const { spacing, size, strength, paragraph } = attrs;

  let fontSize = theme.global.fontSize[sanitizePropsWithBase(size)];
  let fontWeight = theme.global.font.fontWeight.normal;
  let fontWeightThick = theme.global.font.fontWeight.thick;
  let lineHeight = theme.global.font.lineHeight;
  let fontFamily = theme.global.font.fontFamily;
  let letterSpacing = theme.global.font.letterSpacing;
  let textTransform = theme.global.font.textTransform;
  let paragraphMarginBottom = theme.global.font.paragraph.marginBottom;

  if(paragraph) {
    fontSize = theme.global.paragraph[sanitizePropsWithBase(size)];
    fontWeight = theme.global.paragraph.fontWeight.normal;
    fontWeightThick = theme.global.paragraph.fontWeight.thick;
    lineHeight = theme.global.paragraph.lineHeight;
    fontFamily = theme.global.paragraph.fontFamily;
    letterSpacing = theme.global.paragraph.letterSpacing;
    textTransform = theme.global.paragraph.textTransform;
    paragraphMarginBottom = theme.global.paragraph.paragraph.marginBottom;
  }

  return css`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    font-family: ${fontFamily};
    letter-spacing: ${letterSpacing};
    text-transform: ${textTransform};
    
    strong, b {
      font-weight: ${fontWeightThick};
    }
    
    p {
      margin-bottom: ${paragraphMarginBottom};
      &:last-child {
        margin-bottom: 0;
      }
    }
  `;
};

export const StyledText = styled.div`
  color: ${props => props.theme.global.colors[`text${props.attrs.strength ? `-${props.attrs.strength}` : ``}`]};
  ${({theme, attrs: { spacing }}) => marginSpacingStyle(theme, spacing)}
  ${(props) => textStyles(props)}
`;
