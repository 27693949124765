import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/fontawesome-pro-regular/faSpinnerThird';
import PropTypes from 'prop-types';
import Loader from './component';
import {
  defaultPropTypes
} from '../propTypes';

Loader.defaultProps = {
  fixed: false,
  overlay: true,
  spinner: true,
  icon: <FontAwesomeIcon icon={faSpinnerThird} spin/>,
  bg: false
};

Loader.propTypes = {
  fixed: PropTypes.bool,
  icon: PropTypes.element,
  overlay: PropTypes.bool,
  spinner: PropTypes.bool,
  bg: PropTypes.bool,
  ...defaultPropTypes
};

export default Loader;
