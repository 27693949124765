import styled, { css } from 'styled-components';
import { elevationStyle, marginSpacingStyle } from '../../../utils/styles';
import { pluckResponsiveSizing, sanitizePropsWithBase, sizeToStyle } from '../../../utils/utils';

const boxStyles = ({attrs: { vfull, withHeader, vcenter, center, elevation, padding, rounded, isIcon, closable }, theme}) => {
  const boxHeight = (vfull && withHeader) ? `calc(100vh - ${sizeToStyle(theme.header.height)})` : (vfull && !withHeader) ? `100vh` : null;
  const paddingStyle = (padding) ? pluckResponsiveSizing(theme, 'spacing', padding) : 0;
  const display = (vcenter || isIcon || closable) ? 'flex' : 'block';
  const borderRadius = (rounded) ? theme.global.borderRadius[sanitizePropsWithBase(rounded)] : 0;

  return css`
    display: ${display};
    border-radius: ${borderRadius};
    ${elevationStyle(theme, elevation)}
    ${boxHeight && css`height: ${boxHeight};`}
    ${vcenter && css`align-items: center;`}
    ${center && css`justify-content: center;`}
    
    ${StyledBoxIcon} {
      margin-right: ${paddingStyle};
    }
  `;
};

export const StyledBox = styled.div`
  position: relative;
  ${props => boxStyles(props)}
  ${({theme, attrs: { spacing }}) => marginSpacingStyle(theme, spacing)}
  ${({theme, attrs: { padding }}) => marginSpacingStyle(theme, padding, true)}
`;

export const StyledBoxChildren = styled.div`
  flex-grow: 1;
`;

export const StyledBoxCloseIcon = styled.div`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledBoxIcon = styled.div`
  font-size: 32px;
`;

