import ThemeModeToggle from 'shared-ui/src/components/navigation/ThemeModeToggle/component';
import { withTheme } from 'styled-components';

ThemeModeToggle.defaultProps = {
  vfull: false,
  withHeader: false,
  vcenter: true,
  center: true,
  elevation: 'none',
  spacing: null
};

export default withTheme(ThemeModeToggle);
