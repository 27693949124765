import PropTypes from 'prop-types';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import ThemeProvider from './component';

config.autoAddCss = false;

ThemeProvider.defaultProps = {
  theme: {},
  dark: false
};

ThemeProvider.propTypes = {
  dark: PropTypes.bool
};

export default ThemeProvider;
