import React from 'react';
import styled, { css } from 'styled-components';
import { pluckBreakpointFromThemeObject } from '../../../utils/utils';
import { generateMediaQueries, marginSpacingStyle } from '../../../utils/styles';
import { deepMerge } from '../../../utils/objects';

const styles = ({theme, attrs: { size, spacing }}) => {
  let { heading, responsive, global } = theme;
  let { level, ...headingOptions } = heading;
  const levelHeading = level[size];
  const { fontSize, ...levelHeadingOptions } = levelHeading;
  const responsiveFontSize = pluckBreakpointFromThemeObject(fontSize, responsive);
  const { fontFamily, fontWeight, textTransform, lineHeight, letterSpacing, marginTop, marginBottom  } = deepMerge(headingOptions, levelHeadingOptions);

  const mediaQueries = generateMediaQueries(theme, fontSize, ['font-size']);

  return css`
    color: ${global.colors['text']};
    ${responsiveFontSize && css`font-size: ${responsiveFontSize};`}
    ${fontFamily && css`font-family: ${fontFamily};`}
    ${fontWeight && css`font-weight: ${fontWeight};`}
    ${textTransform && css`text-transform: ${textTransform};`}
    ${lineHeight && css`line-height: ${lineHeight};`}
    ${letterSpacing && css`letter-spacing: ${letterSpacing};`}
    ${marginTop && !spacing && css`margin-top: ${marginTop};`}
    ${marginBottom && !spacing && css`margin-bottom: ${marginBottom};`}
  `;
};

export const StyledHeading = styled.h1`
  ${props => styles(props)}
  ${({theme, attrs: { spacing }}) => marginSpacingStyle(theme, spacing)}
`;
