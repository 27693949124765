import PropTypes from 'prop-types';
import Text from './component';
import {
  defaultPropTypes,
  sizePropType,
  spacingPropType
} from '../propTypes';

Text.defaultProps = {
  as: 'div',
  paragraph: false,
  spacing: { bottom: 'sm' },
  text: '',
  strength: undefined
};

Text.propTypes = {
  as: PropTypes.oneOf(['p', 'small', 'span', 'div', 'strong', 'b']),
  size: sizePropType,
  paragraph: PropTypes.bool,
  spacing: spacingPropType,
  text: PropTypes.string,
  strength: PropTypes.oneOf(['strong', 'weak', 'xweak']),
  ...defaultPropTypes
};

export default Text;
