import React from 'react';
import styled, { css } from 'styled-components';
import { pluckColorFromTheme } from 'shared-ui/src/utils/utils';
import { Box } from 'shared-ui';

const colorStyles = ({theme, attrs: { status, color }}) => {
  const { tag: { color: tagColor } } = theme;
  const tag = tagColor[color];

  let colors = {
    bgColor: tagColor['default'].background,
    borderColor: tagColor['default'].borderColor,
    textColor: tagColor['default'].color,
  };

  if(tag) {
    colors = {
      bgColor: tag.background,
      borderColor: tag.borderColor,
      textColor: tag.color,
    };
  }

  if(status) {
    const statusColor = pluckColorFromTheme(theme, `status-${status}`);
    const statusOverlayColor = pluckColorFromTheme(theme, `status-overlay-${status}`);
    colors.bgColor = statusColor;
    colors.borderColor = statusColor;
    colors.textColor = statusOverlayColor;
  }

  return css`
    background-color: ${colors.bgColor};
    border-color: ${colors.bgColor};
    color: ${colors.textColor};
  `;
};

const generalStyles = ({theme: { tag:{ fontWeight, fontSize } }}) => {
  return css`
    ${fontWeight && css`font-weight: ${fontWeight};`}
    ${fontSize && css`font-size: ${fontSize};`}
  `;
};

export const StyledTagBox = styled(Box)`
  display: inline-block;
  white-space: nowrap;
  ${props => colorStyles(props)}
  ${props => generalStyles(props)}
`;
