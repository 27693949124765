import Switch from 'shared-ui/src/components/ui/Switch/component';

Switch.defaultProps = {
  backgroundColorChecked: '#2193F3',
  backgroundColorUnchecked: '#ccc',
  backgroundColorButton: '#fff',
  sliderWidth: 26,
  sliderHeight: 26,
  width: 60,
  height: 34,
  translate: 26,
  sliderIconLeft: null,
  sliderIconRight: null
};

export default Switch;
