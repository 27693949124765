import React from 'react';
import { ThemeProvider } from 'shared-ui';
import BumbagThemeProvider from 'shared-bumbag/src/components/ThemeProvider';
import theme from 'shared-assets/theme';

export default ({ element, pathname }) => {
  return (
    <ThemeProvider theme={theme}>
      <BumbagThemeProvider>
        {element}
      </BumbagThemeProvider>
    </ThemeProvider>
  );
};
