import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/fontawesome-pro-regular/faSpinnerThird';
import { StyledButton } from './styles';

const handleOnMouseMove = e => {
  e.target.style.setProperty('--buttonX', `${e.nativeEvent.offsetX}px`);
  e.target.style.setProperty('--buttonY', `${e.nativeEvent.offsetY}px`);
};

const ButtonComponent = forwardRef(
  (
    {
      children,
      htmlType,
      ariaLabel,
      type: buttonType,
      className,
      icon,
      iconPosition,
      loading,
      disabled,
      onClick,
      spacing,
      padding,
      size,
      elevation,
      block,
      shape,
      ghost,
      style,
      ...rest
    },
    ref
  ) => {
    const optionalProps = {};
    if (ariaLabel) optionalProps['aria-label'] = ariaLabel;


    const attrs = {
      elevation,
      iconOnly: (children === undefined || children === null),
      block,
      buttonType,
      shape,
      ghost,
      loading,
      size,
      spacing,
      padding
    };

    return (
      <StyledButton
        attrs={attrs}
        {...rest}
        ref={ref}
        onClick={!loading && !disabled ? onClick : undefined}
        type={htmlType}
        disabled={disabled}
        className={className + ' box'}
        onMouseMove={handleOnMouseMove}
        style={style}
        {...optionalProps}
      >
        <div className="ninja-cut">

        </div>
        {loading && (
          <FontAwesomeIcon icon={faSpinnerThird} spin className="left" />
        )}
        {!loading && icon && iconPosition === 'left' && (
          <span className={iconPosition}>{icon}</span>
        )}
        <span className="label">{children}</span>
        {!loading && icon && iconPosition === 'right' && (
          <span className={iconPosition}>{icon}</span>
        )}
      </StyledButton>
    );
  }
);

export default ButtonComponent;
