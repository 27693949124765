import React from 'react';
import styled, { css } from 'styled-components';
import { transition } from '../../../utils/mixins';
import { elevationStyle, marginSpacingStyle } from '../../../utils/styles';
import { sanitizePropsWithBase } from '../../../utils/utils';

const sizeStyle = ({ attrs: { size, shape, iconOnly }, theme: { global: { controls }, button } }) => {
  const sanitizedSize = sanitizePropsWithBase(size);
  let { height, horizontalPadding, borderRadius, borderWidth } = controls.size[sanitizedSize];
  let { textTransform, textShadow, fontWeight, fontSize } = button.size[sanitizedSize];
  if (shape === 'circle') borderRadius = '50%';

  return css`
    font-size: ${fontSize};
    text-transform: ${textTransform};
    font-weight: ${fontWeight};
    text-shadow: ${textShadow};
    
    height: ${height};
    border-width: ${borderWidth};
    border-radius: ${borderRadius};
    
    ${!iconOnly ? css`
      padding: 0 ${horizontalPadding};
      
      .left {
        margin-right: calc(${horizontalPadding} - 4px);
      }
  
      .right {
        margin-left: calc(${horizontalPadding} - 4px);
      }
    ` : css`
      width: calc(${height} + 1px);
    `}
  `;
};

const disabledStyle = () => {
  return css`
    opacity: ${props => props.theme.global.opacity.strong};
    &:hover {
      cursor: not-allowed;
    }
  `;
};

const loadingStyle = () => {
  return css`
    opacity: ${props => props.theme.global.opacity.strong};
    &:hover {
      cursor: not-allowed;
    }
  `;
};

const colorStyle = ({
  disabled,
  attrs: {
    loading,
    ghost,
    buttonType
  },
  theme: {
    button: { type }
  }
}) => {

  const button = type[buttonType];

  let colors = {
    bgColor: button.default.background,
    bgColorHover: button.hover.background,
    borderColor: button.default.borderColor,
    borderColorHover: button.hover.borderColor,
    textColor: button.default.color,
    textColorHover: button.hover.color
  };

  if (ghost) {
    colors.bgColor = 'transparent';
    colors.textColor = button.default.background;
    colors.textColorHover = button.hover.color;
  }

  return css`
    background: ${colors.bgColor};
    border-color: ${colors.borderColor};
    color: ${colors.textColor};
    
    &:after {
      content: '';
      position: absolute;
      top: var(--buttonY);
      left: var(--buttonX);
      background: white;
      height: 250px;
      width: 250px;
      border-radius: 250px;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: -1;
      transition: all .8s ease-out;
    }
    
    ${!loading && !disabled && css`
      &:hover {
        border-color: ${colors.borderColorHover};
        color: ${colors.textColorHover};
        background-color: ${colors.bgColorHover};
      }
      
      &:focus {
       
      }
      
      &:active {      
        &:after {
          opacity: 0.3;
          transform: translate(-50%, -50%) scale(0);
          transition: 0s;
        }
      }
    `} 
    
  `;
};

export const StyledButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  text-transform: none;
  text-decoration: none;
  overflow: hidden;
  margin: 0;
  padding:0;
  outline: none;
  z-index: 1;
  border-style: solid;
  
  ${({theme}) => theme.button.transition && transition(theme.button.transition)}
  ${props => props.attrs.block && css`width: 100%;`}
  ${props => sizeStyle(props)}
  ${props => colorStyle(props)}
  ${props => elevationStyle(props.theme, props.attrs.elevation)}
  
  ${props => props.disabled && disabledStyle(props)}
  ${props => props.attrs.loading && loadingStyle(props)}
  
  ${({theme, attrs: { spacing }}) => marginSpacingStyle(theme, spacing)}
  ${({theme, attrs: { padding }}) => marginSpacingStyle(theme, padding, true)}
  
  span {
    position: relative;
    z-index: 2;
    
    &.label {
      //top:-1px;
    }
  }
  
`;
